.timeline {
  background-color: #12372A; /* Dark background for contrast */
  padding: 40px;
  border-radius: 0px;
  margin: auto; /* Center the timeline */
  text-align: left; /* Align text to the left for readability */
  box-shadow: 0 0px 0px rgba(0,0,0,0.1); /* Soft shadow for depth */
  max-width: auto; /* Optimal reading width */
  color: #FFFFFF; /* Light text for contrast */
}
@media (max-width: 768px) {
  .timeline {
    padding: 20px;
    margin: auto;
    max-width: 100%;
  }
}

.timeline h2 {
  font-size: 28px;
  color: #F2CD00; /* Gold color for headings */
  margin-bottom: 30px;
  font-weight: 600; /* Medium font weight for headings */
}

.timeline ul {
  list-style: none;
  padding: 0;
}

.timeline-item {
  background-color: #1E1E1E; /* Slightly lighter than the background for layering effect */
  color: #CCCCCC; /* Light grey for text */
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  border-left: 4px solid #F2CD00; /* Gold border for accent */
  transition: transform 0.3s ease-out, box-shadow 0.3s ease; /* Smooth transitions for hover effects */
}

.timeline-item:hover {
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 8px 16px rgba(0,0,0,0.2); /* Enhanced shadow for emphasis */
}
.timeline-item-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.timeline-item-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}

.timeline-item-exit {
  opacity: 1;
}

.timeline-item-exit-active {
  opacity: 0;
  transform: translateX(30px);
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}
.vertical-timeline {
  --timeline-color: #F2CD00; /* Gold for line and icons */
}

.vertical-timeline-element--work .vertical-timeline-element-content {
  background: #1E1E1E !important; /* Dark background for each item */
  color: #fff !important; /* Light text color */
}

.vertical-timeline-element--work .vertical-timeline-element-content .vertical-timeline-element-title,
.vertical-timeline-element--work .vertical-timeline-element-content .vertical-timeline-element-subtitle {
  color: #fff; /* Ensures text is white */
}

.vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #121212, inset 0 2px 0 rgba(0,0,0,.08), 0 3px 0 4px rgba(0,0,0,.05) !important; 
}

