.main-section {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center-align children horizontally */
    justify-content: center; /* Center content vertically */
    min-height: 100vh;
    background-color: #1a1a1a;
    color: #ccc;
    position: relative;
    text-align: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 0;
  }
  
  .content-container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column; /* Ensure content is also flex for better control */
    align-items: center; /* Center-align the content including the logo */
    padding: 20px;
    max-width: 600px; /* Adjust if necessary for your content */
  }
  
  .logo-container {
    margin-bottom: 20px;
  }
  
  .main-logo {
    width: 100%; /* This will now respect the max-width of its parent */
    max-width: auto; /* Adjust this to the desired logo size on large screens */
    height: auto;
    animation: fadeIn 2s ease-out;
  }
  
  .main-content {
    background: rgba(0, 0, 0, 0.85);
    border-radius: 10px;
    backdrop-filter: blur(5px);
    padding: 20px;
    animation: fadeInUp 1.5s ease-out;
  }
  
  .main-section h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #FFD700;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  }
  
  .main-section p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media (max-width: 1024px) {
    /* Adjust sizes and spacing for tablets and smaller desktops */
    .main-logo {
      width: 100%; /* Adjust the logo size on smaller screens */
    }
    
    .main-section h1 {
      font-size: 2.5rem;
    }
    
    .main-section p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    /* Adjust sizes and spacing for landscape phones and small tablets */
    .main-logo {
      width: 75%;
    }
    
    .main-section h1 {
      font-size: 2rem;
    }
    
    .main-section p {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    /* Adjust sizes and spacing for portrait phones */
    .main-logo {
      width: 70%;
    }
    
    .main-section h1 {
      font-size: 1.5rem;
    }
    
    .main-section p {
      font-size: 0.8rem;
    }
  }