.footer {
    background-color: #1a1a1a; 
    color: #ccc; 
    padding: 40px 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .footer-top {
    display: flex;
    justify-content: space-between;
    align-items: start;
    max-width: 1200px;
    margin: 0 auto; 
    flex-wrap: wrap; 
  }
  
  .footer-branding {
    flex-basis: 30%; 
  }
  
  .footer-logo {
    max-width: 300px;
    margin-bottom: 20px; 
  }
  
  .brand-statement {
    font-size: 1.2rem;
    color: #ddd; 
  }
  
  .footer-links {
    flex-basis: 60%; 
    display: flex;
    justify-content: space-evenly;
  }
  
  .link-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .link-column h4 {
    font-size: 1.4rem;
    margin-bottom: 15px; 
    color: #FFD700; 
  }
  
  .link-column a {
    color: #ccc;
    text-decoration: none; 
    margin-bottom: 10px; 
    transition: color 0.3s ease; 
  }
  
  .link-column a:hover,
  .social-media a:hover {
    color: #FFD700; 
  }
  
  .social-media {
    display: flex; 
    justify-content: start; 
    gap: 10px; 
  }
  
  .social-media a {
    display: inline-flex;
    align-items: center; 
    justify-content: center; 
  }
  
  .social-media i {
    font-size: 24px; 
    color: #ccc; 
  }
  
  
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px; 
    border-top: 1px solid #333; 
    padding-top: 20px; 
  }
  
  .footer-bottom p {
    font-size: 0.9rem;
    color: #aaa; 
  }
  
  @media (max-width: 768px) {
    .footer {
      padding: 20px 10px; 
    }
  
    .footer-top {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-branding,
    .footer-links {
      flex-basis: 100%; 
      text-align: center;
    }
  
    .footer-logo {
      max-width: 150px; 
      margin-bottom: 15px;
    }
  
    .brand-statement {
      font-size: 1rem; 
    }
  
    .link-column h4 {
      font-size: 1.2rem;
    }
  
    .link-column {
      align-items: center; 
      margin-bottom: 20px; 
    }
  
    .social-media {
      margin-top: 0; 
    }
  
    .footer-bottom p {
      font-size: 0.8rem; 
    }
  }
  