.meetTheCar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    padding: 50px;
    background-color: black;
    overflow: hidden;
  }
  
  .meetTheCar-title {
    text-align: center;
    margin-bottom: 20px;
    color: white;
    font-size: 2.5rem; /* Larger font size for the title */
    animation: fadeIn 2s ease-out; /* Fade-in animation for the title */
  }
  
  .meetTheCar-subtitle {
    color: #FFD700; /* A gold-ish color to make subtitles pop */
    font-weight: bold; /* Make subtitle text bold */
  }
  
  .meetTheCar-contentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    min-height: 500px;
  }
  
  .meetTheCar-textContent {
    color: white;
    text-align: center;
    padding: 20px;
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .meetTheCar-textContent:hover {
    transform: scale(1.05); /* Slightly enlarge the text section on hover */
  }
  
  .meetTheCar-canvasContainer {
    width: 500px;
    height: 500px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.6);
    transition: box-shadow 0.3s ease; /* Smooth transition for shadow on hover */
  }
  
  .meetTheCar-canvasContainer:hover {
    box-shadow: 0px 0px 20px 7px rgba(54, 216, 81, 0.7); /* Change shadow color and size on hover */
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  