.navbar {
    padding: 0.5rem 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
  }
  
  .navbar-brand img {
    display: block;
    margin: 0 auto;
    align-items: center;
  }
  
  .navbar-nav {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .nav-item.nav-link {
    margin: 0 70px; /* Increase spacing between navbar items */
    padding: 1rem 1rem; 
    font-weight: bold; 
    font-size: 1.1rem; 
    color: #333; 
    transition: color 0.2s;
  }
  
  .nav-item.nav-link:hover {
    color: #12372A;
  }
  

  @media (max-width: 767.98px) {
    .navbar-nav {
      flex-direction: column; 
    }
  
    .nav-item.nav-link {
      margin: 5px 0; 
      text-align: center;
    }
    .navbar-brand img {
        max-height: 100px;
        padding-left: 110%;
      }
  }
  
  .navbar-toggler {
    border: none; 
  }
  .navbar.scrolled {
    background-color: #000 !important; /* Navbar background turns black when scrolled */
    color: white; /* Default text color when scrolled */
  }
  
  /* Text color change when navbar is scrolled */
  .navbar.scrolled .nav-link,
  .navbar.scrolled .navbar-brand {
    color: #fff !important; /* Ensures text is white when navbar is scrolled */
  }
  
  /* Hover effect for links when navbar is scrolled */
  .navbar.scrolled .nav-item.nav-link:hover {
    color: #12372A; /* Example: Change to a different color on hover to ensure visibility against the black background */
  }